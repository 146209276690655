import { Container, Row, Col, Navbar, Nav, NavLink, NavItem } from "reactstrap"
import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import logo from '../logo_VNP.svg';

const TopBar = props => {

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen)

    return (
        <Navbar color="white" sticky="top" className="p-0 border-bottom border-accent2">
            <Container fluid>
                <Row>
                    <Col xs={6} sm={3} className="order-2 order-sm-1 d-flex justify-content-start align-items-center">
                        <a href={`${process.env.PUBLIC_URL}/`}>
                            <img alt="logo" src={logo} className="" style={{ height: "2em" }}/>
                        </a>
                    </Col>
                    <Col xs={12} sm={6} className="order-1 order-sm-2 d-flex justify-content-center align-items-center">
                        <h1 className="fw-bold" style={{fontSize: "3rem"}}>
                            <a href={`${process.env.PUBLIC_URL}/`} style={{textDecoration: "none"}}>
                                <span className="text-secondary">video</span><span className="text-primary">Node</span><span className="text-accent2">.pro</span>
                            </a>
                        </h1>
                    </Col>
                    <Col xs={6} sm={3} className="order-3 order-sm-3 d-flex justify-content-end align-items-center">
                        <Nav>
                            <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle nav caret>
                                    Documentation
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                    <NavItem>
                                        <NavLink href="/doc/api">API</NavLink>
                                    </NavItem>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem>
                                <NavLink href="mailto:info@videonode.pro&subject=Request%20for%20info%20about%20videoNode.pro" className="fw-bold">Contact</NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    )
}

export default TopBar