import './App.css';
import './vnp.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import TopBar from './components/TopBar';
import Index from './components';
import Api from './components/api';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Index/>} />
        <Route path={'/doc/api' } element={<Api/>}/>
        <Route path="*" element={
          <Navigate replate to={"/"} />
        }/>
      </Routes>
    </BrowserRouter>
  )
}

function App() {
  return (
    <div className="App">
      <TopBar/>
      <Router/>
    </div>
  );
}

export default App;
