import { Container, Row, Col, Badge, Button } from "reactstrap"
import { Card, CardBody, CardTitle, CardText, CardSubtitle, } from "reactstrap"
import vpnDiagram from '../img/vnp_diagram.svg'
import screenshotEdu from '../img/videonode.pro.edu.screenshot.jpg'
import screenshotPro from '../img/videonode.pro.pro.screenshot.jpg'

const Index = props => {

    return (

        <Container className="mt-3 h-100">
            <Row>
                <Col className="text-white">
                    <h2>
                        <Badge color="accent2" pill={true}>
                            <span className="fw-bold">Easy and Powerful Video Streaming</span>
                        </Badge>
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col className="col-12">
                    <h2>Check out our demo dashboards</h2>
                </Col>
                
                <Col xs={12} sm={0} md={1}></Col>
                <Col xs={12} sm={5} md={4} className="d-flex align-items-top mt-2">
                    <Card>
                        <CardBody className="border-bottom border-accent2 pb-0">
                            <CardTitle tag="h3" className="text-accent2 fw-bold">
                                EDU
                            </CardTitle>
                            <CardSubtitle className="mb-2 text-muted" tag="h6">
                                Dashboard
                            </CardSubtitle>
                        </CardBody>
                        <CardBody className="d-flex justify-content-center pt-3">
                            <img
                                alt="EDU dashboard"
                                src={screenshotEdu}
                                width="75%"
                            />
                        </CardBody>
                        <CardBody>
                            <CardText className="text-primary">
                                Easy to operate dashboard, ideal for conference streaming.
                            </CardText>
                           
                            <Button outline color="primary" onClick={()=> {
                                window.open("https://demo.videonode.pro/dashboard/edu", '_blank', 'noopener,noreferrer')
                            }}>
                                Check it out
                            </Button>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs={12} sm={1} md={2}></Col>
                <Col xs={12} sm={5} md={4} className="d-flex align-items-top mt-2">
                    <Card>
                        <CardBody className="border-bottom border-accent2 pb-0">
                            <CardTitle tag="h3" className="text-primary fw-bold">
                                PRO
                            </CardTitle>
                            <CardSubtitle className="mb-2 text-muted" tag="h6">
                                Dashboard
                            </CardSubtitle>
                        </CardBody>
                        <CardBody className="d-flex justify-content-center pt-3">
                            <img
                                alt="EDU dashboard"
                                src={screenshotPro}
                                className="w-75"
                            />
                        </CardBody>
                        <CardBody>
                            <CardText className="text-primary">
                                Explore the complete range of powerful features available on <b>videoNode.pro</b>
                            </CardText>
                           
                            <Button outline color="primary" onClick={()=> {
                                window.open("https://demo.videonode.pro/dashboard/pro", '_blank', 'noopener,noreferrer')
                            }}>
                                Check it out
                            </Button>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            
{/* 
            <Row>
                <Col className="col-5">
                    <h2>Stream anything...</h2>
                    WHIP, SRT any capable browser or device (Larix)
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
                </Col>
            </Row>

            <Row>
                <Col className="col-5">
                   
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
                    <h2>...from anywhere...</h2>
                    WHIP, SRT any capable browser or device (Larix)
                </Col>
            </Row>

            <Row>
                <Col className="col-5">
                    <h2>...to anywhere</h2>
                    WHEP, Youtube, Twitch
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
               
                </Col>
            </Row>

            

            <Row>
                <Col className="col-7 d-flex align-items-center text-white">
                    <h2>Make up your video with different inputs</h2>
                </Col>
                <Col className="col-5 d-flex align-items-center" style={{maxHeight: "20rem"}}>
                    <img src={vpnDiagram} className="p-2 bg-white h-75 mw-100"/>
                </Col>
            </Row>

            <Row>
                <Col className="col-5">
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
                    <h2>Configure and update the layout and audio using the API or a Dashboard</h2>
                </Col>
            </Row>
           

            <Row>
                <Col className="col-5">
                    <h2>Not only from cameras!</h2>
                    Build you live video from your brand, product or company assets!
                    * Airport timetables
                    * Canal Metro
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
                </Col>
            </Row>

            <Row>
                <Col className="col-5">
                    Try our demo Dashboards!
                </Col>
                <Col className="col-7 d-flex align-items-center text-white">
                    <h2>A dashboard for every use case:</h2>
                    * PRO
                    * Conferencing
                    * (e)Sports
                    Build your own with our SDK or ask us to do it!
                </Col>
            </Row>

            <Row>
                <Col>
                * Start streaming within minutes


                * Generate video signal from different intpus:
                    - Text
                    - Images
                    - Animations
                    - Video files
                    - Audio files
                    - Video/Audio streams

                * Control your video using the API
                    - Templates
                * Or build you custom dashboard

                * Enrich your video signal
                    - Watermark
                    - Customization
                    - Monitor

                * Compose from different video stream sources

                * Output to:
                    - RTMP: Youtube, Twitch, Facebook
                    - SRT: Chain several videoNodes or send to hour production facility (Wowza, Bitmovin)
                    - WHEP WebRTC: share directly using the web browsers

                * we provide some predefined dashboard for uses cases:
                    - (e)-Sports
                    - Conferencing
                    - You can build your own (using provided API and/or SDK) or ask us to do it!
                </Col>
            </Row>
            <Row>
                <Col>
                FAQ:
                    * Can I connect videonode.pro output to its own?
                    * Can I customize video output settings such as resolution, bitrate or codec?
                    * Can I run VideoNode.pro on premises?
                    * Can I stream to web withoyt Youtube/Twitch, etc?
                    * How can I send video?
                        - Every App/Service capable of RTMP,SRT,WHIP
                        - WebRTC, share the link
                        - SRT visit SRTAllianze or use Larix App

                </Col>
            </Row>
            <Row>
                <Col>
                Tech:
                    * Loosy CSS based for styling
                    

                </Col>
            </Row> */}
        </Container>
    )
}

export default Index