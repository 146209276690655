import React from 'react'
import 'rapidoc'
import logo from '../logo_VNP.svg'

const Api = props => {

    //const apiUrl= 'https://videonode.pro/openapi/videonode.pro.openapi.json'
    const apiUrl= `${process.env.PUBLIC_URL}/videonodepro.openapi.json`

    return <rapi-doc
      spec-url = {apiUrl}
      render-style = "read"
      layout="row"
      info-description-headings-in-navbar="true"
      show-components = {true}
      style = {{ height: "100vh", width: "100%" }}
      theme = "light"
      header-color="#ffffff" 
      text-color="#004355"
      primary-color="#ff8d22"
      show-method-in-nav-bar="false"
      use-path-in-nav-bar={false}
      nav-bg-color="#004355"
      nav-text-color="#ffffff"
      show-header={false}
      allow-spec-file-download={true}
      show-curl-before-try={false}
    >
        <img slot="logo" src={logo} style={{width: "50px"}} alt="logo"/>
    </rapi-doc>

}

export default Api